.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/***************************************************/
.cp-login-box {
    width: 100%;
    box-shadow: 0 2px 4px rgba(0,0,0,.23);
    padding: 80px 30px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: relative;
    margin-top: 80px;
    display: table;
}

.cp-login-box .user-pic {
    background: #fff;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    box-shadow: 0 2px 4px rgba(0,0,0,.23);
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: -55px;
}
.cp-login-box .user-pic img {
    width: 100%;
    height: auto;
    border: 5px solid #fff;
    border-radius: 100%;
}
cp-login-box .user-pic img {
    vertical-align: middle;
}
cp-login-box .user-pic img {
    border: 0;
}
/*form*/
.cp-login-form .input-group {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px 0;
    width: 100%;
}
.cp-login-form ul li {
    list-style: none;
    margin-bottom: 20px;
    clear: both;
    width: 100%;
    float: left;
}
.cp-login-form .material .material-input {
    margin: 0px;
    padding: 0px;
}
.cp-login-form .material .material-group {
    margin: 0px;
    padding: 0px;
    float: left;
}
.cp-login-form .forgot {
    text-align: right;
    font-style: italic;
}
.cp-login-form .or-sp {
    width: 100%;
    float: left;
    text-align: center;
    margin: 10px 0 16px;
}
.cp-login-form ul {
    margin: 0px;
    padding: 0px;
}

.cp-social-login {
    text-align: center;
}
.cp-login-form .cp-social-login li {
    margin-bottom: 10px;
}
.cp-login-form .signup {
    width: 100%;
    float: left;
    border-top: 1px solid #ccc;
    text-align: center;
    margin-top: 20px;
    padding-top: 17px;
    font-style: italic;
}
.cp-social-login h3 {
    margin: 0 0 7px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}
.cp-login-form .input-group-addon {
    background: none;
    border: 0px;
    border-right: 1px solid #ccc !important;
    padding: 0px;
    text-align: center;
    width: 45px;
    color: #888888;
    font-size: 15px;
}
.cp-login-form .input-group .form-control {
    border: 0px;
    box-shadow: none;
    padding: 0 0 0 15px;
    height: 21px;
    line-height: 21px;
    font-size: 14px;
}
.cp-login-form .btn-submit {
    float: right;
    color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.23);
    border-radius: 2px;
    height: 40px;
    line-height: 38px;
    padding: 0 15px;
    font-size: 15px;
    text-transform: uppercase;
}
.cp-login-form .or-sp strong {
    width: 40px;
    height: 31px;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0,0,0,.23);
    display: inline-block;
    color: #fff;
    line-height: 31px;
    position: relative;
}
.cp-login-form .or-sp strong:before {
    position: absolute;
    width: 70px;
    height: 1px;
    background: #ccc;
    content: "";
    left: -80px;
    top: 15px;
}
.cp-login-form .or-sp strong:after {
    position: absolute;
    width: 70px;
    height: 1px;
    background: #ccc;
    content: "";
    right: -80px;
    top: 15px;
}
.cp-login-form .material .material-bar:before, .cp-login-form .material .material-bar:after {
    bottom: -10px;
}

/*Color theme*/
.cp-login-form .btn-submit, .cp-login-form .or-sp strong {
    background-color: #32aae1;
}
.cp-login-form .forgot a, .signup a{
  color:#32aae1;
}
