a {
	/*color: #212529;*/
	text-decoration: none;
}

.bg-icon {
	/*background: #1967d2;*/
	background: #6c757d;
	color: #FFF;
	padding: 4px 8px;
	border-radius: 16px;
}

.text-bold {
	font-weight: 500;
}

.cajaPregunta {
    margin-bottom: 20px;
    background-color: #F4F5FC;
    border-radius: 18px;
    padding: 20px;
}

.cajaRespuesta {
    background-color: #d9d9e0;
    padding: 10px;
    border-radius: 18px;
    margin-top: 10px;
    display: block;
    width: 100%;
    cursor: pointer;
}

.bg-error {
    background-color: #ff6d6d;
    color: #fff;
    border-radius: 5px;
}

.bg-seleccion {
    background-color: #c7c7c7;
}

.pdfviewer {
    width: 100%;
    height: 220px;
}

#contenido-guia blockquote {
    margin: 10px 0;
    border: 3px solid #CCC;
    padding: 4px 14px;
    font-family: 'Courier New', Courier, monospace;
}

#contenido-guia p {
    margin: 6px 0;
}

@media (min-width: 961px) {
	.container {
		/*width: 960px;*/
        width: 1100px;
	}
    .pdfviewer {
        height: 448px;
    }
}